import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../config';

import { buttonPosition } from './config';
import { btnStyle } from './textStyles';

interface IOptions {
  text?:
    | {
        text?: string | undefined;
        variable?: string | undefined;
      }
    | undefined;
  position?: number | undefined;
  styles: PIXI.TextStyle;
}

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title: PIXI.Text;

  private subtitle: PIXI.Text;

  private btn: PIXI.Text;

  private additionalText: PIXI.Text;

  private bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: MessageBannerProps) {
    super();
    this.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2 + 30);
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner(props.bonusEnd);
    this.title = this.initText({
      text: {
        text: props.title?.key,
      },
      position: props.titlePosition,
      styles: props.titleStyles,
    });
    this.subtitle = this.initText({
      text: {
        text: props.subtitle?.key,
        variable: props.subtitle?.var,
      },
      position: props.subtitlePosition,
      styles: props.subtitleStyles,
    });
    this.additionalText = this.initText({
      text: {
        text: props.additionalText,
      },
      position: props.additionalPosition,
      styles: props.additionalStyles,
    });
    this.btn = this.initBtn(props.btnText);
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    this.init();
    if (props.onInitCallback) {
      props.onInitCallback();
    }
  }

  private init(): void {
    this.addChild(this.banner);
    this.addChild(this.title);
    this.addChild(this.subtitle);
    this.addChild(this.additionalText);
    this.addChild(this.btn);
  }

  private initBanner(bonusEnd: boolean): PIXI.Sprite {
    let banner: PIXI.Sprite;
    if (bonusEnd) {
      banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBannerWin));
    } else {
      banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    }
    banner.anchor.set(0.5);
    return banner;
  }

  private initText(options: IOptions): PIXI.Text {
    const text = new PIXI.Text(
      i18n.t<string>(options.text?.text || '', { var: options.text?.variable }),
      options.styles,
    );
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    updateTextScale(text, isMobile ? 500 : 700, isMobile ? 150 : 250);
    text.y = options.position ? options.position : 70;
    text.visible = !!options.text;

    return text;
  }

  private initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(i18n.t<string>(btnText || ''), btnStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0.5);
    updateTextScale(btn, isMobile ? 450 : 550, isMobile ? 250 : 250);
    btn.y = buttonPosition;
    btn.visible = !!btnText;

    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }
}

export default MessageBanner;
