import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { EventTypes, IUserBalance } from '../../global.d';
import {
  client,
  getBetAmountGql,
  getUserGql,
  getWinAmountGql,
  setBetAmount,
  setBrokenGame,
  setCoinValue,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import {
  calcBottomContainerHeight,
  countCoins,
  formatNumber,
  isMobilePortrait,
  normalizeBalance,
  normalizeCoins,
  showCurrency,
  updateTextScale,
} from '../../utils';
import Button from '../button';
import ViewContainer from '../components/container';
import { bottomContainerTextStyle, eventManager } from '../config';

import InfoBtn from './infoBtn';

class BottomContainer extends ViewContainer {
  private isInitialized = false;

  private rect: PIXI.Graphics;

  private infoBtn: Button;

  private balance: PIXI.Text;

  private win: PIXI.Text;

  private bet: PIXI.Text;

  // TODO have to be refactored
  private currency = 'FUN';

  private maxFontHeight: number;

  private maxFontWidth: number;

  constructor() {
    super();

    this.rect = new PIXI.Graphics();
    this.addChild(this.rect);
    this.infoBtn = new InfoBtn();
    this.addChild(this.infoBtn);
    this.maxFontHeight = 30;
    this.maxFontWidth = 300;
    this.balance = new PIXI.Text(this.createText('balance', 0), bottomContainerTextStyle);
    this.balance.anchor.set(0, 0.5);
    this.addChild(this.balance);
    this.win = new PIXI.Text(
      this.createText(
        'win',
        formatNumber(
          this.currency,
          normalizeCoins(
            countCoins({
              totalAmount: setWinAmount(),
            }),
          ),
          true,
        ),
      ),
      bottomContainerTextStyle,
    );
    this.win.anchor.set(0.5, 0.5);
    this.win.visible = !setBrokenGame();
    this.addChild(this.win);
    this.bet = new PIXI.Text(
      this.createText(
        'bet',
        formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: setBetAmount() })), true),
      ),
      bottomContainerTextStyle,
    );
    this.bet.anchor.set(1, 0.5);
    this.addChild(this.bet);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LABEL, this.setWinLabelVisible.bind(this, false));
    eventManager.addListener(EventTypes.SHOW_WIN_LABEL, this.setWinLabelVisible.bind(this, true));
    eventManager.addListener(EventTypes.UPDATE_TOTAL_WIN_VALUE, this.updateTotalWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_WIN_VALUE, this.updateWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_USER_BALANCE, this.updateUserBalance.bind(this));
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.updateBetAmount(setBetAmount()));

    this.getUserBalance();
    this.getBetAmount();
    this.getWinAmount();
  }

  private getBetAmount(): void {
    client.watchQuery<{ betAmount: number }>({ query: getBetAmountGql }).subscribe(({ data }) => {
      this.updateBetAmount(data.betAmount);
    });
  }

  private getWinAmount(): void {
    client.watchQuery<{ winAmount: number }>({ query: getWinAmountGql }).subscribe(({ data }) => {
      this.updateWinAmount(data.winAmount);
    });
  }

  private getUserBalance(): void {
    client
      .query<{ user: IUserBalance }>({
        query: getUserGql,
      })
      .then((res) => {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, res.data.user.balance);
      });
  }

  private async updateUserBalance(balance: { currency: string; amount: number }): Promise<void> {
    if (!this.isInitialized) {
      this.currency = balance.currency;
      this.updateBetAmount(setBetAmount());
      this.updateWinAmount(setWinAmount());

      this.isInitialized = true;
    }
    this.updateBalance(balance.amount);
  }

  private createText(key: 'balance' | 'win' | 'bet' | 'totalWin', value: number | string): string {
    return `${i18n.t(key).toUpperCase()}: ${value}`;
  }

  private setWinLabelVisible(visible: boolean): void {
    this.win.visible = visible;
  }

  private updateWinValue(newValue: number): void {
    if (setUserLastBetResult().coinValue === setCoinValue()) {
      this.setWinLabelVisible(true);
      this.win.text = `${this.createText('win', newValue)}`;
    }
  }

  private updateTotalWinValue(newValue: number): void {
    this.setWinLabelVisible(true);
    const coins = setUserLastBetResult().id ? setUserLastBetResult().coinValue : setCoinValue();
    this.win.text = `${this.createText(
      'totalWin',
      formatNumber(this.currency, normalizeCoins(newValue, coins), showCurrency(this.currency)),
    )}`;
  }

  private updateBalance(amount: number): void {
    this.balance.text = this.createText(
      'balance',
      formatNumber(this.currency, normalizeBalance(amount), showCurrency(this.currency)),
    );
    updateTextScale(this.balance, this.maxFontWidth, this.maxFontHeight);
  }

  private updateWinAmount(amount: number): void {
    const coins = setUserLastBetResult().id ? setUserLastBetResult().coinValue : setCoinValue();
    this.win.text = this.createText(
      'win',
      formatNumber(
        this.currency,
        normalizeCoins(countCoins({ totalAmount: amount }), coins),
        showCurrency(this.currency),
      ),
    );
    updateTextScale(this.win, this.maxFontWidth, this.maxFontHeight);
  }

  private updateBetAmount(amount: number): void {
    this.bet.text = this.createText(
      'bet',
      formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: amount })), showCurrency(this.currency)),
    );
    updateTextScale(this.bet, this.maxFontWidth, this.maxFontHeight);
  }

  private resize(width: number, height: number): void {
    const containerHeight = calcBottomContainerHeight(width, height);
    const mobilePortrait = isMobilePortrait(width, height);

    this.rect.clear();
    this.rect.beginFill(0x000000, 0.46);
    this.rect.drawRect(0, 0, width, containerHeight);
    this.rect.endFill();

    const infoBtnHeight = height * (mobilePortrait ? 0.025 : 0.04);
    const padding = width * 0.02;
    this.infoBtn.height = infoBtnHeight;
    this.infoBtn.width = infoBtnHeight;
    this.infoBtn.x = padding + this.infoBtn.width / 2;

    const infoBtnOffset = this.infoBtn.width + 15;

    this.maxFontHeight = mobilePortrait ? containerHeight * 0.6 : containerHeight * 0.8;
    this.maxFontWidth = mobilePortrait ? (width - padding * 3) / 2 : (width - padding * 3 - infoBtnOffset) / 3;

    updateTextScale(this.win, this.maxFontWidth, this.maxFontHeight);
    updateTextScale(this.bet, this.maxFontWidth, this.maxFontHeight);
    updateTextScale(this.balance, this.maxFontWidth, this.maxFontHeight);

    if (mobilePortrait) {
      this.infoBtn.y = containerHeight / 4;
      this.balance.position.set(padding, containerHeight * (3 / 4));
      this.win.position.set(width / 2, containerHeight / 4);
      this.bet.position.set(width - padding, containerHeight * (3 / 4));
    } else {
      this.infoBtn.y = containerHeight / 2;
      this.balance.position.set(padding + infoBtnOffset, containerHeight / 2);
      this.win.position.set((width + infoBtnOffset) / 2, containerHeight / 2);
      this.bet.position.set(width - padding, containerHeight / 2);
    }

    this.pivot.set(0, containerHeight);
    this.position.set(0, height);
  }
}

export default BottomContainer;
