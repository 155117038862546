/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import { EventTypes, GameMode } from '../../global.d';
import {
  configGql,
  getSlotGql,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import i18n from '../../i18next';
import SlotMachine from '../../slotMachine';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import { findSubstituteCoinAmount, isBuyFeatureEnabled, wrap } from '../../utils';
import { fallBackReelPosition } from '../../utils/fallback';

import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';

const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const [slotMachineActive, setSlotMachineActive] = useState(false); // TODO interim support
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery<{ slot: ISlotData }, { input: { id: string } }>(getSlotGql, {
    variables: { input: { id } },
    onCompleted({ slot }) {
      const lines = slot.lines.map((_, index) => index);

      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0]!,
        isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
      });

      setReelSetId(slot.reels[0]!.id);
      setGameMode(GameMode.REGULAR);

      let coinValue;
      let coinAmount;
      if (setBrokenGame() || setBrokenBuyFeature()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
      SlotMachine.initSlotMachine(slot, wrap(setIsSpinInProgress, false), wrap(setIsSlotBusy, false));

      // Overriding these methods to avoid certified files.
      SlotMachine.getInstance().throwTimeoutError = () => {
        // It was an old error spec, so I had to get a new one
        eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
        if (!setIsRevokeThrowingError()) {
          setIsRevokeThrowingError(true);
          setIsTimeoutErrorMessage(true);
          setStressful({
            show: true,
            type: 'network',
            message: i18n.t('errors.UNKNOWN.UNKNOWN'),
          });
        }
        // Stop because error screen is not in front
        fallBackReelPosition();
      };

      slotMachine.current = SlotMachine.getInstance();
      setSlotMachineActive(true); // TODO interim support
    },
  });

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      const application = slotMachine.current.getApplication();
      pixiContainerRef.current?.appendChild(slotMachine.current.getApplication().view);
      resize(application)();
      window.addEventListener(EventTypes.RESIZE, resize(application));
      return () => window.removeEventListener(EventTypes.RESIZE, resize(application));
    }
    return undefined;
  }, [slotMachineActive]); // TODO interim support

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  return <div className={styles['canvasWrapper']} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
