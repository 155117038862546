import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setIsBuyFeaturePopupOpened } from '../../gql';
import { calcPercentage, canPressSpin } from '../../utils';
import { eventManager } from '../config';

import Button from '.';

class SpinBtn extends Button {
  private count: PIXI.Text;

  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isSlotStopped: boolean;

  private isFreeSpinsWin: boolean;

  private isBuyFeaturePopup: boolean;

  constructor() {
    super('spin', 105);
    this.interactive = !this.isDisabled;
    if (setBrokenGame()) {
      this.gameMode = GameMode.FREE_SPINS;
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isSlotStopped = false;
    this.isFreeSpinsWin = false;
    this.isBuyFeaturePopup = false;
    this.count = this.initCount();
    this.addChild(this.count);
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.count.visible = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_AUTO_SPINS_LEFT, (autoSpinsLeft: string) => {
      this.count.text = autoSpinsLeft;
      this.count.visible = !!autoSpinsLeft && this.isAutoSpins;
      this.setScaleCountText();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, (isBuyPopup: boolean) => {
      this.isBuyFeaturePopup = isBuyPopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, (isBuyPopup: boolean) => {
      this.isBuyFeaturePopup = isBuyPopup;
      this.handleDisable();
    });
  };

  private initCount = (): PIXI.Text => {
    const count = new PIXI.Text('', {
      fontSize: 20,
      whiteSpace: 'normal',
      fontWeight: '700',
      align: 'center',
    });
    count.visible = false;
    count.anchor.set(0.5);
    count.y = 0;
    count.x = 0;

    return count;
  };

  private handleUpdateIntent = (): void => {
    this.updateIntent(this.isSlotBusy || this.isAutoSpins ? 'stop' : 'spin');
  };

  private handleDisable = (): void => {
    const isDisable = !canPressSpin({
      gameMode: this.gameMode,
      isFreeSpinsWin: this.isFreeSpinsWin,
      isSpinInProgress: this.isSpinInProgress,
      isSlotBusy: this.isSlotBusy,
      isSlotStopped: this.isSlotStopped,
      isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
    });

    this.setDisable(isDisable);
  };

  private handleClick = (): void => {
    if (!this.isDisabled) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  private setScaleCountText = () => {
    const scaleValue = this.width / (110 / 100);
    this.count.scale.set(scaleValue / 100);
  };

  public override handlePosition(): void {
    this.setScaleCountText();
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 11)
      : calcPercentage(this.applicationSize.width, 89);
    let y = calcPercentage(this.applicationSize.height, 91);
    this.setSize(calcPercentage(this.applicationSize.width, 8.2));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 45));
      x = calcPercentage(this.applicationSize.width, 50);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 57);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 21));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 50);
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;
