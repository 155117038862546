import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode, updateTextScale } from '../../utils';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from '../background/config';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import { FEATURE_BTN_LANDSCAPE_TEXT_SCALE, FEATURE_BTN_PORTRAIT_TEXT_SCALE } from './config';
import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
} from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  isLeftHandMode: boolean;

  private windowWidth: number;

  private windowHeight: number;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.text = this.initBuyFeatureText();
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);
    this.addChild(this.text);

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, this.handleLeftHandMode.bind(this));
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5);

    if (text.width >= this.btn.width - 20) {
      updateTextScale(text, this.btn.width - 20, 90);
    } else {
      isMobile ? text.scale.set(FEATURE_BTN_PORTRAIT_TEXT_SCALE) : text.scale.set(FEATURE_BTN_LANDSCAPE_TEXT_SCALE);
    }
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      AudioApi.play({ type: ISongs.SFX_BuyFeature });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.text.width >= this.btn.width - 20) {
      updateTextScale(this.text, this.btn.width - 20, 90);
    } else {
      isMobile
        ? this.text.scale.set(FEATURE_BTN_PORTRAIT_TEXT_SCALE)
        : this.text.scale.set(FEATURE_BTN_LANDSCAPE_TEXT_SCALE);
    }
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    this.setPosition(width, height);
    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    const isLeftHandMode = setIsLeftHandMode() && isMobile;

    if (width > height) {
      this.pivot.x = isLeftHandMode ? -this.btn.width / 2 : this.btn.width / 2;
      this.pivot.y = 0;
      this.x = isLeftHandMode ? SLOTS_CONTAINER_WIDTH + 60 : -60;
      this.y = SLOTS_CONTAINER_HEIGHT / 2 + 60;
      this.scale.set(1);
    } else {
      this.pivot.x = isLeftHandMode ? this.btn.width / 2 : -this.btn.width / 2;
      this.pivot.y = -this.btn.height / 2;
      this.x = isLeftHandMode ? SLOTS_CONTAINER_WIDTH - 90 : 90;
      this.y = SLOTS_CONTAINER_HEIGHT + 60;
      this.scale.set(1.3);
    }
  }

  private handleLeftHandMode = (_isLhm: boolean): void => {
    this.setPosition(this.windowWidth, this.windowHeight);
  };

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
